<template>
  <div>
    <draggable class="list-group" tag="ul" group="additional" v-bind="$attrs" v-on="$listeners">
      <li
        class="list-group-item"
        v-for="(additional, index) in $attrs.value"
        :key="`additional_${index}`"
        @drag="actionIndex = index"
        @mouseover="actionIndex = index"
        @mouseleave="actionIndex = -1"
        @click="
          canEdit
            ? $router.push({
                name: 'ContentEditor',
                params: { contentId: additional.id },
                query: { type: 'Additional' },
              })
            : {}
        "
      >
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div class="flex-fill">
            <hs-icon
              class="font-size-lg"
              style="width: 20px;"
              variant="light"
              :icon="
                index === actionIndex ? 'bars' : getAdditionalIcon(additional.media ? additional.media.type : 'Other')
              "
            />
            <span class="h-100 font-size-lg mb-0 ml-2">
              {{ additional.title }}
              <small v-if="!additional.media">{{
                $t(
                  'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.no-have-material'
                )
              }}</small>
            </span>
          </div>
          <div class="actions">
            <template v-if="index === actionIndex">
              <hs-button
                class="text-dark"
                variant="link"
                v-b-tooltip.bottom="$t('actions.view')"
                @click="onView(additional)"
              >
                <hs-icon class="font-size-lg" variant="light" icon="eye" />
              </hs-button>

              <hs-button
                v-if="canEdit"
                class="text-dark"
                variant="link"
                v-b-tooltip.bottom="$t('actions.edit')"
                @click="
                  $router.push({
                    name: 'ContentEditor',
                    params: { contentId: additional.id },
                    query: { type: 'Additional' },
                  })
                "
              >
                <hs-icon class="font-size-lg" variant="light" icon="pencil" />
              </hs-button>

              <hs-button
                v-if="canEdit"
                class="text-dark mr-3"
                variant="link"
                v-b-tooltip.bottom="$t('actions.delete')"
                @click.stop="onDelete(additional)"
              >
                <hs-icon class="font-size-lg" variant="light" icon="trash-alt" />
              </hs-button>
            </template>
          </div>
          <hs-icon
            class="font-size-lg mr-lg-5"
            variant="light"
            style="width: 20px"
            :class="[isPublished(additional.available) ? 'text-success-dark' : 'text-danger']"
            :icon="isPublished(additional.available) ? 'check-circle' : 'times-circle'"
          />
        </div>
      </li>
    </draggable>
    <AdditionalPreviewModal :selectedAdditional="selectedAdditional" />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { extraContentsService, mediaService } from '@/services';
import ToastHelper from '@/shared/helpers/toast';
import AdditionalPreviewModal from './components/AdditionalPreviewModal';
import Confirm from '@/shared/mixins/Confirm';
export default {
  inheritAttrs: false,
  name: 'NestedDraggableAdditional',
  mixins: [Confirm],
  data() {
    return {
      actionIndex: -1,
      selectedAdditional: {},
      drag: false,
    };
  },
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AdditionalPreviewModal,
    draggable,
  },
  methods: {
    getAdditionalIcon(type) {
      const types = ['Document', 'Video', 'Text', 'Image', 'Audio', 'Other'];
      const variants = ['file', 'video', 'text', 'file-image', 'microphone', 'engine-warning'];

      return variants[types.indexOf(type)];
    },
    isPublished(available) {
      return available || available === null;
    },
    async onView(additional) {
      if (!additional.media) {
        ToastHelper.dangerMessage(
          this.$t(
            'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.toast.empty-media'
          )
        );
        return;
      }
      const { data: media } = await mediaService.get(additional.media.id);
      this.selectedAdditional = { ...additional, media };
      this.$bvModal.show('additional-preview-modal');
    },
    async onDelete({ id }) {
      try {
        const additionalIndex = this.$attrs.value.findIndex(additional => additional.id === id);

        const confirmBoxOptions = {
          okTitle: this.$t(
            'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.confirm-box.delete.ok-title'
          ),
          cancelTitle: this.$t(
            'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.confirm-box.delete.cancel-title'
          ),
          contentTitle: this.$t(
            'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.confirm-box.delete.content-title'
          ),
          contentDescription: this.$t(
            'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.confirm-box.delete.content-description'
          ),
          variant: 'cherry',
          icon: 'trash-alt',
        };

        if (await this.showConfirmBox(confirmBoxOptions)) {
          this.$attrs.value.splice(additionalIndex, 1);
          await extraContentsService.delete(id);

          ToastHelper.successMessage(
            this.$t(
              'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.toast.success'
            )
          );
        }
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t(
            'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.toast.error'
          )
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-group-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 80px;
  cursor: pointer;
}
.ghost {
  opacity: 0.5;
  background: $grey-20;
}

.actions {
  min-width: 155px;
}
</style>
