<template>
  <div>
    <draggable
      tag="ul"
      group="additional-medias"
      v-model="localAdditionalMedias"
      class="list-group"
      handle=".handle"
      @end="$emit('move', localAdditionalMedias)"
    >
      <transition-group type="transition">
        <li
          class="d-flex align-items-center mt-3"
          v-for="additionalMedia in localAdditionalMedias"
          :key="additionalMedia.id"
        >
          <hs-icon variant="light" icon="bars" class="handle mr-3" />

          <div v-if="!additionalMedia['editing-title']" class="d-flex">
            <div class="text-nowrap">{{ additionalMedia.title }}</div>
            <button class="btn btn-link ml-2 p-0" @click="$set(additionalMedia, 'editing-title', true)">
              <hs-icon variant="solid" icon="pencil-alt" class="font-size-lg" />
            </button>
          </div>

          <hs-input
            v-else
            :value="additionalMedia.title"
            @change="$set(additionalMedia, 'title', $event)"
            @keyup.enter="additionalMediaTitleChanged(additionalMedia)"
          />

          <a
            v-if="additionalMedia.file_url"
            target="_blank"
            :href="additionalMedia.file_url"
            class="flex-fill ml-3 mr-2 font-weight-bold text-right text-truncate text-decoration-none text-muted"
          >
            {{ additionalMedia.file_name }}
          </a>

          <div
            v-if="additionalMedia.view_modal"
            class="flex-fill ml-3 mr-2 font-weight-bold text-right text-truncate file-view-modal-button"
            @click="showViewModal(additionalMedia)"
          >
            <div>{{ additionalMedia.file_name }}</div>
          </div>

          <button class="btn btn-link ml-2 p-0" @click="$emit('remove', additionalMedia.id)">
            <hs-icon variant="light" icon="trash-alt" class="font-size-lg text-danger" />
          </button>
        </li>
      </transition-group>
    </draggable>
    <ViewerModal :additionalMedia="modalAdditionalMedia" />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import ViewerModal from './ViewerModal';

export default {
  name: 'AdditionalMediasList',
  props: {
    additionalMedias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localAdditionalMedias: JSON.parse(JSON.stringify(this.additionalMedias)),
      modalAdditionalMedia: {},
    };
  },
  components: {
    draggable,
    ViewerModal,
  },
  methods: {
    additionalMediaTitleChanged(additionalMedia) {
      this.$emit('change', { id: additionalMedia.id, title: additionalMedia.title });
      additionalMedia['editing-title'] = false;
    },
    showViewModal(additionalMedia) {
      this.modalAdditionalMedia = additionalMedia;
      this.$bvModal.show(this.modalAdditionalMedia.view_modal);
    },
  },
  created() {
    this.localAdditionalMedias.forEach(am => {
      switch (am.type) {
        case 'Video':
          switch (am.host) {
            case 'Youtube':
              am['file_name'] = `https://youtu.be/${am['host_key']}`;
              am['file_url'] = `https://youtu.be/${am['host_key']}`;
              break;
            case 'Vimeo':
              am['file_name'] = `https://vimeo.com/${am['host_key']}`;
              am['file_url'] = `https://vimeo.com/${am['host_key']}`;
              break;
            case 'SparkVideos':
              am['file_name'] = this.$t(
                'sparkmembers.contents.views.editor.components.lesson.components.additional-medias.components.list.index.item-file-name.text'
              );
              am['view_modal'] = 'viewer-modal';
          }
          break;
        case 'Text':
          am['file_name'] = this.$t(
            'sparkmembers.contents.views.editor.components.lesson.components.additional-medias.components.list.index.item-file-name.text'
          );
          am['view_modal'] = 'viewer-modal';
          break;
        default:
          am['file_name'] = decodeURIComponent(am.s3_file_url).split('/')[6];
          am['file_url'] = am.s3_file_url;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.handle {
  cursor: grabbing;
}
.file-view-modal-button {
  cursor: pointer;
}
</style>
