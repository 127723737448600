























































import { Component, Mixins } from 'vue-property-decorator';
import ToastHelper from '@/shared/helpers/toast';
import Confirm from '@/shared/mixins/Confirm';
import Loading from '@/components/Loading.vue';
import { namespace } from 'vuex-class';
import { courseService, lessonService, liveMeetService } from '@/services';

const CourseModule = namespace('course');
const ProductModule = namespace('product');

@Component({
  name: 'Main',
  components: {
    Loading,
    MButton: () => import('@/shared/components/MButton.vue'),
    LiveMeetsTable: () => import('./components/LiveMeetsTable.vue'),
    NoLives: () => import('./components/NoLives.vue'),
    RecordLiveCard: () => import('./components/RecordLiveCard.vue'),
    TransmissionTestCard: () => import('./components/TransmissionTestCard.vue'),
  },
})
export default class LivesList extends Mixins(Confirm) {
  @CourseModule.State selectedCourse!: any;
  @CourseModule.Action setCourseContents!: any;
  @ProductModule.State selectedProduct!: any;

  lives: any[] = [];
  currentPage: number = 1;
  perPage: number = 20;
  totalNumberOfLives: number = 0;
  loading: boolean = true;
  mainModule: any = {};
  prefixLang: string = 'sparkmembers.contents.views.lives';

  get canEditProduct() {
    return this.$ACL.canProductConfig();
  }

  async created() {
    await this.loadContentLives();
  }

  async changePage(page: any) {
    this.lives = [];
    this.currentPage = page;
    await this.loadContentLives();
  }

  async loadContentLives() {
    try {
      this.loading = true;
      const { data: module } = await courseService.getCourseModules(
        this.$route.params.courseId || this.selectedCourse.id
      );
      const moduleId = module.course_modules[0].id;
      this.mainModule = module;

      const payload = [{ key: 'page', value: this.currentPage }, { key: 'per_page', value: this.perPage }];
      const { course_contents } = await courseService.getCourseContents(moduleId, payload);
      const liveInfo = await liveMeetService.getProductLives(this.selectedProduct.id, payload);

      this.totalNumberOfLives = liveInfo.total_count;
      course_contents.forEach(content => {
        liveInfo.live_meet_lessons.find(live => {
          if (live.id === content.lesson.id) this.lives.push({ ...content, status: live.status });
        });
      });
      this.setCourseContents(this.lives);
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.error.load`));
    } finally {
      this.loading = false;
    }
  }

  async deleteLive(targetLive) {
    try {
      const contentIndex = this.lives.findIndex(live => live.id === targetLive.id);

      const confirmBox = await this.showConfirmBox({
        okTitle: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.ok-title`),
        cancelTitle: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.cancel-title`),
        contentTitle: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.content-title`),
        contentDescription: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.content-description`),
        variant: 'cherry',
        image: require('@/assets/images/SalesReport/remove-robot.svg'),
      } as any);

      if (await confirmBox) {
        this.loading = true;
        await courseService.deleteCourseContent(targetLive.id, targetLive);
        await lessonService.delete(targetLive.lesson);
        if (contentIndex < this.lives.length - 1) {
          const { course_contents } = await courseService.getCourseContents(targetLive.course_module_id);
          const contentIds = course_contents.map(item => item.id);
          await courseService.normalizeCourseContentsOrder(contentIds);
        }
        this.lives.splice(contentIndex, 1);
        ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.delete.success`));
      }
    } catch (error) {
      ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.delete.error`));
    } finally {
      this.loading = false;
    }
  }
}
