<template>
  <PageLayout>
    <template #header>
      <PageHeader
        :title="headerTitle"
        :backOnlyMobile="false"
        back-route="ContentLives"
        :back-text="
          $t(`sparkmembers.contents.views.editor.components.live_meet.components.page-header.back-button-label`)
        "
      />
    </template>
    <Loading v-if="isLoading" class="tlw-h-96" />
    <section v-else class="container-fluid">
      <LiveMeetEditor @createLive="createLive" @editLive="editLive" @deleteLive="deleteLive" :liveInfo="liveSelected" />
    </section>
  </PageLayout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/Loading.vue';
import PageLayout from '@/layout/PageLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import LiveMeetEditor from '@/components/LiveMeetEditor';
import ToastHelper from '@/shared/helpers/toast';
import { analyticsService, courseService, lessonService } from '@/services';
import ThumbnailMixin, { CHANGING_THUMBNAIL, UPLOADING_THUMBNAIL } from '@/mixins/ThumbnailMixin';
import Confirm from '@/shared/mixins/Confirm';
import dayjs from 'dayjs';

export default {
  components: {
    Loading,
    LiveMeetEditor,
    PageHeader,
    PageLayout,
  },
  mixins: [ThumbnailMixin, Confirm],
  data() {
    return {
      liveMeetId: null,
      mainModule: null,
      content: {},
      liveSelected: null,
      headerTitle: '',
      isLoading: true,
      prefixLang: 'sparkmembers.contents.views.lives',
    };
  },
  created() {
    if (this.$route.name == 'EditLive') {
      this.liveSelected = this.$route.params.liveInfo.lesson;
      this.headerTitle = this.liveSelected.title;
      this.mainModule = this.$route.params.liveInfo.course_module_id;
    } else {
      this.mainModule = this.$route.params.module[0];
    }
    this.isLoading = false;
  },
  computed: {
    ...mapState({
      course: state => state.course.selectedCourse,
      selectedSchool: state => state.school.selectedSchool,
    }),
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    async editLive(form) {
      try {
        this.isLoading = true;
        const releaseDate = dayjs(`${form.release_at}${form.time}`)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]');
        const endDate = dayjs(dayjs(`${form.release_at}${form.time}`))
          .add(form.duration.value, 'minute')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]');
        const media = form.thumbnail
          ? await this.onCreateLiveThumbnail(form.thumbnail.blob, form.title)
          : form.cover_image;
        const content_ids = form.addMedias.map(m => m.id);

        let lessonData = {
          content_ids,
          id: form.id,
          title: form.title,
          release_at: releaseDate,
          lock_at: endDate,
          description: form.description,
        };

        media
          ? (lessonData = { ...lessonData, cover_image_id: media.id })
          : (lessonData = { ...lessonData, cover_image_id: null });

        await lessonService.update(lessonData);

        ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.edit.success`));
      } catch (e) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.edit.error`));
      } finally {
        this.$router.push({ name: 'ContentLives', params: { module: this.mainModule } });
      }
    },
    async createLive(form) {
      try {
        this.isLoading = true;
        const module = this.mainModule;
        const releaseDate = dayjs(`${form.release_at}${form.time}`)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]');
        const media = form.thumbnail ? await this.onCreateLiveThumbnail(form.thumbnail.blob, form.title) : null;
        const endDate = dayjs(dayjs(`${form.release_at}${form.time}`))
          .add(form.duration.value, 'minute')
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss[Z]');
        const content_ids = form.addMedias.map(m => m.id);

        let lessonData = {
          title: form.title,
          type: 'LiveMeetLesson',
          release_at: releaseDate,
          content_ids,
          lock_at: endDate,
          description: form.description,
        };

        if (media) lessonData = { ...lessonData, cover_image_id: media.id };

        const lesson = await lessonService.create(lessonData);

        const content = await courseService.createCourseContent(module.id, {
          available: true,
          order: module.course_content_ids.length,
          course_module_id: module.id,
          content_id: lesson.id,
          content_type: 'Lesson',
        });

        const school = this.selectedSchool;
        school.extra_settings.show_top_bar = 'false';

        await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });

        ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.create.success`));

        analyticsService.track({
          event: 'Lesson created',
          props: {
            product_id: parseInt(this.$route.params.id),
            course_id: this.course.id,
            school_id: this.selectedSchool.id,
            lesson_type: 'live_meet_lesson',
          },
        });
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.create.error`));
      } finally {
        this.$router.push({ name: 'ContentLives', params: { module: this.mainModule } });
      }
    },
    async deleteLive(targetLive) {
      const content = this.$route.params.liveInfo;
      try {
        const confirmBox = await this.showConfirmBox({
          okTitle: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.ok-title`),
          cancelTitle: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.cancel-title`),
          contentTitle: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.content-title`),
          contentDescription: this.$t(`${this.prefixLang}.confirm-box.delete-live-meet.content-description`),
          variant: 'cherry',
          image: require('@/assets/images/SalesReport/remove-robot.svg'),
        });

        if (confirmBox) {
          this.isLoading = true;
          await courseService.deleteCourseContent(content.id, content);
          await lessonService.delete(targetLive);

          ToastHelper.successMessage(this.$t(`${this.prefixLang}.toast.delete.success`));
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t(`${this.prefixLang}.toast.delete.error`));
      } finally {
        this.$router.push({ name: 'ContentLives', params: { module: this.mainModule } });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
