<template>
  <b-modal
    id="viewer-modal"
    :title="additionalMedia.title"
    size="lg"
    header-class="p-3"
    body-class="py-3 pb-4"
    centered
    hide-footer
  >
    <MediaViewer v-if="additionalMedia" class="mt-3" :media="additionalMedia" />
  </b-modal>
</template>

<script>
import MediaViewer from '@/components/MediaViewer';
export default {
  name: 'ViewerModal',
  props: {
    additionalMedia: {
      type: Object,
      required: true,
    },
  },
  components: {
    MediaViewer,
  },
};
</script>
