import { lookup as mimeByExtension } from 'mime-types';

const prefix = 'sparkmembers.contents.views.editor.components';

export default (translate, productType) => [
  {
    id: 'youtube',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.youtube.name`),
    icon: 'play-circle',
    active: false,
  },
  {
    id: 'vimeo',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.vimeo.name`),
    icon: 'video',
    active: false,
  },
  {
    id: 'sparkVideos',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.video.name`),
    icon: 'cloud-upload',
    active: false,
    badge: true,
    features: ['members_sparkvideos'],
  },
  {
    id: 'audio',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.audio.name`),
    icon: 'microphone',
    active: false,
  },
  {
    id: 'ebook',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.ebook.name`),
    icon: 'book-alt',
    supportedFiles: [mimeByExtension('.pdf')],
    active: false,
  },
  {
    id: 'file',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.file.name`),
    icon: 'file-spreadsheet',
    supportedFiles: [mimeByExtension('.pdf'), mimeByExtension('.xls'), mimeByExtension('.xlsx'), '.csv'],
    active: false,
  },
  {
    id: 'text',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.text.name`),
    icon: 'file-word',
    active: false,
  },
  {
    id: 'image',
    title: translate(`${prefix}.${productType}.components.additional-medias.components.list.index.types.image.name`),
    icon: 'image',
    active: false,
  },
];
