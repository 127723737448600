<template>
  <div>
    <div v-if="isLoading" class="d-flex flex-column justify-content-center">
      <hs-loading />
    </div>
    <div v-else>
      <div class="d-flex flex-column flex-lg-row mt-5 px-0 px-lg-2">
        <div class="col-xl-9 px-0 px-lg-auto">
          <div class="rounded-sm bg-white p-4 shadow-sm">
            <h5 class="font-weight-bold text-dark">
              {{ $t(`sparkmembers.contents.views.editor.components.live_meet.components.live_info.title`) }}
            </h5>
            <p class="font-size-sm grey-40 mt-2">
              {{ $t(`sparkmembers.contents.views.editor.components.live_meet.components.live_info.subtitle`) }}
            </p>
            <div class="d-flex flex-column flex-lg-row mt-4">
              <div class="col-xl-5 pl-0 pr-2">
                <hs-group>
                  <label>{{
                    $t(`sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.date`)
                  }}</label>
                  <hs-date
                    v-model="form.release_at"
                    :label-no-date-selected="$t('date.format')"
                    :disabled="cannotBeEdited"
                    :min="minDate"
                    :required="true"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  >
                    <template slot="button-content">
                      <hs-icon variant="regular" icon="calendar-alt"></hs-icon>
                    </template>
                  </hs-date>
                </hs-group>
              </div>
              <div class="col-xl-3 px-0 px-lg-2">
                <hs-group>
                  <div>
                    <label>{{
                      $t(`sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.schedule`)
                    }}</label>
                    <hs-icon
                      icon="info-circle"
                      class="ml-1 informative-icon"
                      :size="14"
                      v-b-tooltip.hover.top="
                        'O horário definido para o encontro vai seguir o fuso horário de Brasília.'
                      "
                    />
                  </div>
                  <hs-time
                    id="schedule"
                    v-model="form.time"
                    :disabled="cannotBeEdited"
                    :state="checkTimeState"
                    placeholder="00:00"
                  />
                  <span data-testid="future-warning" class="text-danger" v-if="showFutureTimeWarning">
                    {{
                      $t(
                        `sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.warnings.future_time`
                      )
                    }}</span
                  >
                  <p v-else class="mb-0 mt-1">
                    {{
                      $t(
                        `sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.warnings.default_time_text`
                      )
                    }}
                  </p>
                </hs-group>
              </div>
              <div class="col-xl-4 px-0 px-lg-2">
                <hs-group>
                  <label>{{
                    $t(`sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.duration`)
                  }}</label>
                  <hs-multiselect
                    :showLabels="false"
                    :options="durations"
                    track-by="value"
                    label="text"
                    :placeholder="
                      $t(
                        `sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.duration_placeholder`
                      )
                    "
                    v-model="form.duration"
                    :disabled="cannotBeEdited"
                  />
                  <span data-testid="no-duration" v-if="noDurationSelected" class="text-danger">{{
                    $t(
                      `sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.warnings.no_duration`
                    )
                  }}</span>
                </hs-group>
              </div>
            </div>
            <span data-testid="time-diff-warning" class="text-danger" v-if="showTimeDiffWarning">{{
              $t(
                `sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.warnings.an_hour_difference`
              )
            }}</span>
            <div data-testid="test-info-silver" class="alert-info-silver" v-if="cannotBeEdited">
              <hs-icon variant="light" icon="info-circle" class="mr-2" size="16" />
              <span>{{
                $t(
                  `sparkmembers.contents.views.editor.components.live_meet.components.live_info.inputs.warnings.disabled_warning`
                )
              }}</span>
            </div>
            <div class="d-flex mt-3">
              <img src="@/assets/images/SparkMembers/Contents/views/Lives/social-proof.svg" class="mr-3" />
              <span class="font-size-xs grey-40">{{
                $t(`sparkmembers.contents.views.editor.components.live_meet.components.live_info.alert_text`)
              }}</span>
            </div>
          </div>
          <div class="rounded-sm bg-white p-4 p-lg-4 mt-4 shadow-sm">
            <h5 class="font-weight-bold text-dark">
              {{ $t(`sparkmembers.contents.views.editor.components.live_meet.components.details.title`) }}
            </h5>
            <p class="grey-40 font-size-sm mt-2">
              {{ $t(`sparkmembers.contents.views.editor.components.live_meet.components.details.subtitle`) }}
            </p>
            <hs-group>
              <label class="font-weight-bold mt-4">{{
                $t(`sparkmembers.contents.views.editor.components.live_meet.components.details.inputs.name_label`)
              }}</label>
              <hs-input
                type="text"
                v-model="form.title"
                :placeholder="
                  $t(
                    `sparkmembers.contents.views.editor.components.live_meet.components.details.inputs.name_placeholder`
                  )
                "
              />
            </hs-group>
            <hs-group>
              <label class="font-weight-bold mt-4">{{
                $t(
                  `sparkmembers.contents.views.editor.components.live_meet.components.details.inputs.description_label`
                )
              }}</label>
              <hs-text-editor
                id="description-editor"
                :initialContent="form.description || ``"
                v-model="form.description"
                @onUpdate="descriptionUpdated"
              />
            </hs-group>
          </div>

          <AdditionalMedias
            class="mt-4 mb-0 mb-lg-5"
            :type="'live_meet'"
            :additionalMedias="form.addMedias"
            @add="additionalMediaAdded"
            @change="additionalMediaChanged"
            @remove="additionalMediaRemoved"
            data-track="lesson extra content"
            @move="additionalMediaMoved"
            @premium="onPremiumFeature"
          />
        </div>
        <div class="col-lg-3 px-0 pl-lg-3">
          <div class="bg-white rounded-sm p-4">
            <h5 class="text-dark font-weight-bold">
              {{ $t(`sparkmembers.contents.views.editor.components.live_meet.components.details.thumbnail.title`) }}
            </h5>

            <p class="font-size-xs mt-2 grey-40">
              {{ $t(`sparkmembers.contents.views.editor.components.live_meet.components.details.thumbnail.subtitle`) }}
            </p>

            <Thumbnail
              class="mt-3"
              :isLoading="isThumbnailLoading"
              :source="thumbnail"
              :isSidebar="true"
              :showMessage="true"
              @selected-image="onSelectedThumbnail"
              @remove-image="onCleanThumbnail"
            />
          </div>
        </div>

        <hsPremiumModal id="premium-modal" @click="requestPremiumFeature" />
      </div>
    </div>
    <div
      class="mobile-sticky d-flex flex-row mb-4 mt-4 mt-lg-0 col-xl-9 px-2"
      :class="liveInfo && !cannotBeEdited ? 'justify-content-between' : 'justify-content-center justify-content-lg-end'"
    >
      <MButton
        v-if="liveInfo && !cannotBeEdited"
        type="button"
        icon="trash-alt"
        :label="$t(`sparkmembers.contents.views.editor.components.live_meet.components.form.btns.delete`)"
        variant="critical-outline"
        @click="$emit('deleteLive', form)"
      />
      <div class="d-flex justify-content-center justify-content-lg-end">
        <MButton
          class="mr-3"
          type="button"
          :label="$t(`sparkmembers.contents.views.editor.components.live_meet.components.form.btns.back`)"
          variant="primary-outline"
          @click="goBack"
        />
        <MButton
          data-testid="test-save-button"
          :label="
            liveInfo
              ? $t(`sparkmembers.contents.views.editor.components.live_meet.components.form.btns.save-back`)
              : $t(`sparkmembers.contents.views.editor.components.live_meet.components.form.btns.save-publish`)
          "
          variant="primary"
          :disabled="$v.form.$invalid || !isFutureTime || !timeBetweenLives"
          @click="emitEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { hsLoading, hsPremiumModal } from '@/components';
import MButton from '@/shared/components/MButton.vue';
import toastHelper from '@/shared/helpers/toast';
import mediaService from '@/sparkmembers/services/media';
import analyticsService from '@/services/analytics';
import AdditionalMedias from '@/sparkmembers/views/Contents/views/Editor/components/AdditionalMedias.vue';
import ThumbnailMixin, { CHANGING_THUMBNAIL, UPLOADING_THUMBNAIL } from '@/mixins/ThumbnailMixin';
import Thumbnail from './Thumbnail.vue';
import { required } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

export default {
  name: 'LiveMeetHerospark',
  mixins: [ThumbnailMixin],
  components: {
    MButton,
    AdditionalMedias,
    hsLoading,
    Thumbnail,
    hsPremiumModal,
  },
  props: {
    liveInfo: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      coverImage: null,
      minDate: dayjs.tz().toDate(),
      form: {
        title: null,
        release_at: null,
        description: '',
        media: null,
        addMedias: [],
        thumbnail: null,
        time: '00:00',
        duration: { value: '60', text: '1 hora' },
      },
      durations: [
        { value: '30', text: '30 minutos' },
        { value: '45', text: '45 minutos' },
        { value: '60', text: '1 hora' },
        { value: '75', text: '1 hora e 15 minutos' },
        { value: '90', text: '1 hora e 30 minutos' },
        { value: '105', text: '1 hora e 45 minutos' },
        { value: '120', text: '2 horas' },
        { value: '135', text: '2 horas e 15 minutos' },
        { value: '150', text: '2 horas e 30 minutos' },
        { value: '165', text: '2 horas e 45 minutos' },
        { value: '180', text: '3 horas' },
        { value: '195', text: '3 horas e 15 minutos' },
        { value: '210', text: '3 horas e 30 minutos' },
        { value: '225', text: '3 horas e 45 minutos' },
        { value: '240', text: '4 horas' },
        { value: '255', text: '4 horas e 15 minutos' },
        { value: '270', text: '4 horas e 30 minutos' },
        { value: '285', text: '4 horas e 45 minutos' },
        { value: '300', text: '5 horas' },
        { value: '315', text: '5 horas e 15 minutos' },
        { value: '330', text: '5 horas e 30 minutos' },
        { value: '345', text: '5 horas e 45 minutos' },
        { value: '360', text: '6 horas' },
      ],
    };
  },
  validations: {
    form: {
      title: {
        required,
      },
      release_at: {
        required,
      },
      time: {
        required,
      },
      duration: {
        required,
      },
    },
  },
  created() {
    if (this.liveInfo) {
      this.coverImage = this.liveInfo.cover_image;
      const time = dayjs(this.liveInfo.release_at).format('HH:mm');
      const release_at = dayjs(this.liveInfo.release_at).format('YYYY-MM-DD');
      const duration = dayjs(this.liveInfo.lock_at).diff(this.liveInfo.release_at, 'minute');
      this.form = Object.assign(this.form, this.liveInfo);
      this.form = {
        ...this.form,
        release_at,
        time,
        duration: this.durations.find(d => d.value == duration),
        addMedias: this.liveInfo.contents || [],
      };
    }
  },
  computed: {
    ...mapState({
      product: state => state.product.selectedProduct,
      course: state => state.course.selectedCourse,
      course_contents: state => state.course.courseContents,
      loggedUser: state => state.auth.loggedUser,
      selectedSchool: state => state.school.selectedSchool,
    }),
    noDurationSelected() {
      return !this.cannotBeEdited && !this.form.duration;
    },
    showTimeDiffWarning() {
      return this.form.release_at && !this.timeBetweenLives && !this.isFinishedOrAvailable && !this.isAboutToStart;
    },
    showFutureTimeWarning() {
      return this.form.release_at && !this.isFutureTime && !this.isFinishedOrAvailable && !this.isAboutToStart;
    },
    checkTimeState() {
      return this.isFinishedOrAvailable || this.isAboutToStart ? null : this.isFutureTime && this.timeBetweenLives;
    },
    cannotBeEdited() {
      return this.isFinishedOrAvailable || (this.liveInfo && this.isAboutToStart);
    },
    isFinishedOrAvailable() {
      const live = this.course_contents.find(content => content.content_id === this.form.id);
      return live && (live.status === 'done' || live.status === 'available');
    },
    isFutureTime() {
      const nowTime = dayjs(dayjs.tz()).format('YYYY-MM-DDTHH:mm:ss');
      const liveDate = this.form
        ? dayjs(`${this.form.release_at}${this.form.time}`).utc()
        : dayjs(this.liveInfo.release_at).utc();
      return this.form.release_at ? Boolean(dayjs(liveDate).diff(nowTime) > 0) : null;
    },
    timeBetweenLives() {
      const date = dayjs(`${this.form.release_at}${this.form.time}`);
      const availability = this.course_contents.every(content => {
        if (content.lesson.id != this.form.id && content.lesson.lock_at) {
          return Math.abs(dayjs(date).diff(content.lesson.lock_at)) >= 5400000;
        } else {
          return true;
        }
      });
      return availability;
    },
    isAboutToStart() {
      const nowTime = dayjs(dayjs.tz()).format('YYYY-MM-DDTHH:mm:ss');
      const releaseDate = this.liveInfo ? this.liveInfo.release_at : dayjs(`${this.form.release_at}${this.form.time}`);
      return this.liveInfo ? Boolean(Math.abs(dayjs(releaseDate).diff(nowTime)) <= 3600000) : false;
    },
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    descriptionUpdated(e) {
      this.form.description = e.getHTML();
      if (this.form.description === '<p></p>') this.description = '';
    },
    goBack() {
      this.$router.push({ name: 'ContentLives', params: { module: this.mainModule } });
    },
    emitEvent() {
      if (this.$route.name == 'CreateLive') this.$emit('createLive', this.form);
      else this.$emit('editLive', this.form);
    },
    async onPremiumFeature(feature) {
      this.premiumFeatureLocation = `lesson ${feature.toLowerCase()}`;
      this.$bvModal.show('premium-modal');
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          location: this.premiumFeatureLocation,
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },
    requestPremiumFeature() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal');
    },
    onSelectedThumbnail(img) {
      this.form.thumbnail = img;
      this.onlyChangeThumbnail(img);
    },
    onCleanThumbnail() {
      this.form.thumbnail = null;
      this.form.cover_image = null;
      this.cleanThumbnail();
    },
    async additionalMediaRemoved(id) {
      this.isLoading = true;
      try {
        const results = await new Promise(resolve => {
          this.form.addMedias = this.form.addMedias.filter(m => m.id !== id);
          return resolve();
        });
        results;
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.live_meet.components.additional-medias.toast-messages.media-remove.error`
          )
        );
      }
      this.isLoading = false;
    },
    async additionalMediaChanged(attrs) {
      try {
        await mediaService.update(attrs);

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.live_meet.components.additional-medias.toast-messages.media-changed.success`
          )
        );
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.live_meet.components.additional-medias.toast-messages.media-changed.error`
          )
        );
      }
    },
    async additionalMediaAdded(attrs) {
      this.isLoading = true;

      try {
        const newAdditionalMedia = (await mediaService.create({ media: { ...attrs } })).data;
        const content_ids = this.form.addMedias.map(m => m.id);

        content_ids.push(newAdditionalMedia.id);

        toastHelper.successMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.live_meet.components.additional-medias.toast-messages.add-media.success`
          )
        );

        this.form.addMedias.push({ ...attrs, id: newAdditionalMedia.id });
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.live_meet.components.additional-medias.toast-messages.add-media.error`
          )
        );
      }

      this.isLoading = false;
    },
    async additionalMediaMoved(additionalMedias) {
      const content_ids = additionalMedias.map(am => am.id);
      if (this.form.media) content_ids.unshift(this.form.media.id);
      try {
        this.form.addMedias = [...additionalMedias];
        if (this.form.media) this.form.addMedias.unshift(this.form.media);
      } catch (e) {
        toastHelper.dangerMessage(
          this.$t(
            `sparkmembers.contents.views.editor.components.live_meet.components.additional-medias.toast-messages.media-moved.error`
          )
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-info-silver {
  background: #e3e3e3;
  padding: 16px;
  color: #6f6f6f;
  font-weight: 400;
  line-height: 20.3px;
  font-size: 'Mulish';
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.grey-40 {
  color: $grey-40;
}

/deep/ .b-form-timepicker {
  label {
    color: $color-neutral-600 !important;
  }
}
/deep/ .hs-multiselect .multiselect--disabled {
  border: 2px solid $grey-ba;
  border-radius: 4px;
  color: $color-neutral-600;
  opacity: 1;
  .multiselect__tags {
    border: none !important;
  }
}
/deep/ .b-form-datepicker:not(.is-invalid),
/deep/ .b-form-timepicker:not(.is-invalid) {
  border: 2px solid $grey-ba;
  border-radius: 4px;
  color: $color-neutral-600;

  i,
  svg {
    color: $color-neutral-600 !important;
  }
  .btn-outline-primary {
    color: $purple-dark;
  }
  .b-calendar-grid-body div[role='button'] > span:hover {
    background: $grey-16 !important;
    color: $purple-dark !important;
  }
  .btn-primary:not(:disabled):not(.disabled).active {
    background-color: $purple-dark !important;
    color: $white !important;
  }
}
/deep/ .b-form-timepicker:not(.is-invalid) {
  .btn-sm {
    padding: 9px !important;
    border-radius: 4px !important;
    &:hover {
      background: $grey-16 !important;
      svg {
        fill: $purple-dark !important;
      }
    }
  }
}

/deep/ .hs-multiselect {
  .multiselect__tags {
    border: 2px solid $grey-ba !important;
    border-radius: 4px !important;
    padding: 0 40px 0 15px !important;
    .multiselect__placeholder,
    .multiselect__single {
      color: $color-neutral-600 !important;
    }
  }

  .multiselect__content-wrapper {
    .multiselect__content {
      padding: 9px !important;
      .multiselect__option--highlight {
        border-radius: 4px !important;
        background: $grey-16 !important;
        color: $grey !important;
      }
    }
  }
}

@media screen and (max-width: $screen-md) {
  .mobile-sticky {
    bottom: 0;
    left: 0;
    position: sticky;
    z-index: 2;
    background: #fff;
    padding: 20px !important;
  }
}

.informative-icon {
  color: #3575d4;
}
</style>
