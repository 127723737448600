<template>
  <div class="bg-white p-4 mb-4 rounded-sm">
    <h5 class="font-weight-bold">
      {{ $t(`sparkmembers.contents.views.editor.components.${this.type}.components.additional-medias.index.title`) }}
    </h5>

    <p class="font-size-sm mt-3 mb-0">
      {{ $t(`sparkmembers.contents.views.editor.components.${this.type}.components.additional-medias.index.subtitle`) }}
    </p>

    <List :additionalMedias="additionalMedias" v-on="$listeners" />

    <hr v-if="additionalMedias.length < 5" class="my-3" />

    <MXButton
      v-if="additionalMedias.length < 5 && !isAdding"
      size="sm"
      icon="far fa-plus"
      variant="tertiary"
      @click="isAdding = true"
    >
      {{
        $t(
          `sparkmembers.contents.views.editor.components.${
            this.type
          }.components.additional-medias.index.add-content-button-label`
        )
      }}
    </MXButton>

    <div v-if="isAdding" class="mt-4">
      <TypeSelector
        :title="
          $t(
            `sparkmembers.contents.views.editor.components.${
              this.type
            }.components.additional-medias.components.list.index.title`
          )
        "
        isAdditional
        :types="types"
        @select="selectedType = $event"
        @premium="$emit('premium', $event)"
      />

      <MediaInput
        v-if="selectedType && !selectedType.premium"
        class="mt-4"
        :type="selectedType.id"
        :configuration="selectedType"
        @save="$emit('add', $event)"
        @cancel="mediaInputCanceled"
      />
    </div>
  </div>
</template>

<script>
import MediaInput from '@/components/MediaInput';
import List from './AdditionalMediasList';
import TypeSelector from './TypeSelector';
import AvailableAdditionalMediaTypes from '@/data/additional_media_types';
import MXButton from '@/shared/components/MXButton.vue';
export default {
  name: 'AdditionalMedias',
  components: {
    MediaInput,
    List,
    TypeSelector,
    MXButton,
  },
  props: {
    type: { type: String },
    additionalMedias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isAdding: false,
      selectedType: null,
      types: AvailableAdditionalMediaTypes(key => this.$t(key), this.type),
    };
  },
  methods: {
    mediaInputCanceled() {
      this.isAdding = false;
      this.selectedType = null;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .lesson-option {
  max-width: 80px;
  margin: 0.5rem 0 !important;
}
</style>
