var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',_vm._g(_vm._b({staticClass:"list-group",attrs:{"tag":"ul","group":"additional"}},'draggable',_vm.$attrs,false),_vm.$listeners),_vm._l((_vm.$attrs.value),function(additional,index){return _c('li',{key:("additional_" + index),staticClass:"list-group-item",on:{"drag":function($event){_vm.actionIndex = index},"mouseover":function($event){_vm.actionIndex = index},"mouseleave":function($event){_vm.actionIndex = -1},"click":function($event){_vm.canEdit
          ? _vm.$router.push({
              name: 'ContentEditor',
              params: { contentId: additional.id },
              query: { type: 'Additional' },
            })
          : {}}}},[_c('div',{staticClass:"w-100 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"flex-fill"},[_c('hs-icon',{staticClass:"font-size-lg",staticStyle:{"width":"20px"},attrs:{"variant":"light","icon":index === _vm.actionIndex ? 'bars' : _vm.getAdditionalIcon(additional.media ? additional.media.type : 'Other')}}),_vm._v(" "),_c('span',{staticClass:"h-100 font-size-lg mb-0 ml-2"},[_vm._v("\n            "+_vm._s(additional.title)+"\n            "),(!additional.media)?_c('small',[_vm._v(_vm._s(_vm.$t(
                'sparkmembers.contents.views.list.components.additional.components.nested-draggable-additional.no-have-material'
              )))]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"actions"},[(index === _vm.actionIndex)?[_c('hs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(_vm.$t('actions.view')),expression:"$t('actions.view')",modifiers:{"bottom":true}}],staticClass:"text-dark",attrs:{"variant":"link"},on:{"click":function($event){return _vm.onView(additional)}}},[_c('hs-icon',{staticClass:"font-size-lg",attrs:{"variant":"light","icon":"eye"}})],1),_vm._v(" "),(_vm.canEdit)?_c('hs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(_vm.$t('actions.edit')),expression:"$t('actions.edit')",modifiers:{"bottom":true}}],staticClass:"text-dark",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$router.push({
                  name: 'ContentEditor',
                  params: { contentId: additional.id },
                  query: { type: 'Additional' },
                })}}},[_c('hs-icon',{staticClass:"font-size-lg",attrs:{"variant":"light","icon":"pencil"}})],1):_vm._e(),_vm._v(" "),(_vm.canEdit)?_c('hs-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:(_vm.$t('actions.delete')),expression:"$t('actions.delete')",modifiers:{"bottom":true}}],staticClass:"text-dark mr-3",attrs:{"variant":"link"},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(additional)}}},[_c('hs-icon',{staticClass:"font-size-lg",attrs:{"variant":"light","icon":"trash-alt"}})],1):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('hs-icon',{staticClass:"font-size-lg mr-lg-5",class:[_vm.isPublished(additional.available) ? 'text-success-dark' : 'text-danger'],staticStyle:{"width":"20px"},attrs:{"variant":"light","icon":_vm.isPublished(additional.available) ? 'check-circle' : 'times-circle'}})],1)])}),0),_vm._v(" "),_c('AdditionalPreviewModal',{attrs:{"selectedAdditional":_vm.selectedAdditional}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }