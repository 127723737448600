<template>
  <hsModal :id="modalId" :size="'lg'" :title="selectedAdditional.title">
    <MediaViewer class="mt-3" :media="selectedAdditional.media" />
  </hsModal>
</template>
<script>
import { hsModal } from '@/components';
import MediaViewer from '@/components/MediaViewer';
export default {
  name: 'AdditionalPreviewModal',
  props: {
    selectedAdditional: {
      type: Object,
    },
  },
  components: {
    MediaViewer,
    hsModal,
  },
  computed: {
    modalId() {
      return 'additional-preview-modal';
    },
  },
};
</script>
