<template>
  <div class="row">
    <div class="col col-xl-9">
      <b-button
        id="btn-collapse"
        class="d-flex justify-content-between align-items-center text-dark text-left shadow-sm py-4 px-3 bg-info-light"
        block
        @click="open = !open"
      >
        <div class="text-truncate">
          <hs-icon variant="light" icon="bars" class="mr-3" />
          <span class="font-size-lg font-weight-bold">{{
            $t('sparkmembers.contents.views.list.components.additional.index.title')
          }}</span>
        </div>
        <hs-icon class="mr-4" variant="light" :icon="open ? 'chevron-down' : 'chevron-right'" />
      </b-button>
      <b-collapse v-model="open" class="ml-3">
        <NestedDraggableAdditional
          :can-edit="canEditProduct"
          v-bind="dragOptions"
          v-model="additional"
          @change="onUpdate"
        />
        <div v-if="canEditProduct" class="d-flex justify-content-around bg-white rounded py-2 buttons-container">
          <hs-button
            class="text-primary"
            :variant="isAddAdditional ? 'light' : 'primary'"
            :disabled="isAddAdditional"
            icon="plus"
            @click="onAdd"
          >
            <b-spinner small type="grow" v-if="isAddAdditional" />
            {{
              isAddAdditional
                ? $t('sparkmembers.contents.views.list.components.additional.index.loading')
                : $t('sparkmembers.contents.views.list.components.additional.index.add-additional')
            }}
          </hs-button>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { extraContentsService } from '@/services';
import NestedDraggableAdditional from './components/NestedDraggableAdditional';
import ToastHelper from '@/shared/helpers/toast';

export default {
  name: 'AdditionalList',
  data() {
    return {
      isAddAdditional: false,
      open: false,
      additional: [],
    };
  },
  components: {
    NestedDraggableAdditional,
  },
  computed: {
    ...mapState({
      course: state => state.course.selectedCourse,
    }),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    canEditProduct() {
      return this.$ACL.canProductConfig();
    },
  },
  async mounted() {
    await this.onFetchAdditionals({ page: 1, per_page: 50, resource_type: 'Course', sort: 'order' });
  },
  methods: {
    onFetchAdditionals({ page, per_page, resource_type, sort }) {
      return new Promise(resolve => {
        extraContentsService
          .get([
            { key: 'direction', value: 'asc' },
            { key: 'page', value: page },
            { key: 'per_page', value: per_page },
            { key: 'resource_id', value: this.course.id },
            { key: 'resource_type', value: resource_type },
            { key: 'sort', value: sort },
          ])
          .then(res => {
            this.additional.push(...res.extra_contents);
            if (res.current_page < res.total_pages) {
              return resolve(this.onFetchAdditionals({ page: page + 1, per_page, resource_type, sort }));
            }
            resolve();
          });
      });
    },
    async onAdd() {
      this.isAddAdditional = true;
      try {
        const additionalLength = this.additional.length;

        const extraContents = await extraContentsService.create({
          title: `${this.$t(
            'sparkmembers.contents.views.list.components.additional.index.title-additional'
          )} ${additionalLength + 1}`,
          resource_type: 'Course',
          resource_id: String(this.course.id),
          order: additionalLength,
        });

        this.additional.push(extraContents);

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.list.components.additional.index.toast.success')
        );
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.list.components.additional.index.toast.error'));
      } finally {
        this.isAddAdditional = false;
      }
    },
    async onUpdate(action) {
      try {
        if (action.moved) {
          const { moved } = action;
          await extraContentsService.update({ ...moved.element, order: moved.newIndex });
        }
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.contents.views.list.components.additional.index.toast.error'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#btn-collapse,
#btn-collapse:focus {
  background-color: $light-blue !important;
}
.buttons-container {
  border: 1px dashed $cyan;
}
</style>
