




































import { namespace } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import PageLayout from '@/layout/PageLayout.vue';
import PageHeader from '@/components/_structures/PageHeader.vue';
import MButton from '@/shared/components/MButton.vue';
import Main from './components/Main/index.vue';
import Additional from '../List/components/Additional/index.vue';
import { getAdminViewSsoUrl } from '@/shared/helpers/general';

const SchoolModule = namespace('school');
const AuthModule = namespace('auth');
const CourseModule = namespace('course');

@Component({
  name: 'ContentLives',
  components: {
    PageLayout,
    PageHeader,
    MButton,
    Main,
    Additional,
    NavigationOptions: () => import('./components/NavigationOptions.vue'),
    AccessRules: () => import('./components/AccessRules/index.vue'),
    InformativeAccordion: () => import('./components/InformativeAccordion.vue'),
  },
})
export default class Lives extends Vue {
  @SchoolModule.Getter getDefaultDomain!: () => string;
  @AuthModule.State loggedUser!: any;
  @CourseModule.State selectedCourse!: any;

  activeItem: string = '';
  productTitle: string = '';
  headerSubtitle: any = '';
  displayInformative: boolean = true;

  data() {
    return {
      activeItem: 'Main',
    };
  }

  get containerClass(): string {
    return this.activeItem == 'AccessRules' ? 'm-0 m-lg-4' : 'm-4';
  }

  contentOptions = [
    { title: 'Programação', name: 'Main' },
    {
      title: 'Regras de Acesso',
      name: 'AccessRules',
    },
    {
      title: this.$t('sparkmembers.contents.views.list.index.content-options.further-material'),
      name: 'Additional',
    },
  ];

  changeComponent(eventValue) {
    this.activeItem = eventValue.value;
  }

  created() {
    if (this.$route.params.additionalTab == 'active') {
      this.activeItem = 'Additional';
    }
  }

  studentViewSchedule() {
    window.open(
      getAdminViewSsoUrl({
        domain: this.getDefaultDomain,
        user: this.loggedUser,
        redirectTo: `/admin_view/courses/${this.selectedCourse.id}`,
      }),
      '_blank'
    );
  }

  @Watch('activeItem')
  shouldDisplayInformative() {
    this.displayInformative = this.activeItem === 'Main';
  }

  checkRoute(route) {
    return route == 'CreateLive' || route == 'EditLive';
  }
}
