<template>
  <PageLayout>
    <template v-slot:header>
      <section class="tlw-px-4 md:tlw-px-10 tlw-py-8 tlw-bg-white d-flex justify-content-between">
        <router-link :to="{ name: 'ContentLives', params: { id: productId, courseId: contentId } }" class="back-route">
          <hs-icon icon="long-arrow-left" /> Voltar para programação do curso.
        </router-link>
        <div>
          <hs-logo height="25" color="#262626" />
        </div>
      </section>
    </template>
    <div :key="is_available">
      <div v-if="is_available" class="jitsi-iframe">
        <div id="jaas-container" class="jitsi-iframe-container" />
      </div>
      <div v-else>Não disponível</div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from '@/layout/PageLayout.vue';
import liveMeetService from '@/services/live_meet';
import jitsiService from '@/services/jitsi';
import hsLogo from '@/components/Logo';

export default {
  components: {
    PageLayout,
    hsLogo,
  },
  data() {
    return {
      live_meet_lesson: {},
      is_available: false,
      jitsi_api: null,
    };
  },
  computed: {
    liveMeetId() {
      return this.$route.params.liveMeetId;
    },
    contentId() {
      return this.$route.query.content;
    },
    productId() {
      return this.$route.query.id;
    },
  },
  methods: {
    async setLiveMeetRoom() {
      this.live_meet_lesson = await liveMeetService.get(this.liveMeetId);
      this.is_available = this.live_meet_lesson.status == 'available';
      this.setJitsiRoom();
    },
    async setModeration() {
      this.jitsi_api.executeCommands();
    },
    setJitsiIframe() {
      this.jitsi_api = jitsiService.getJitsiAPI(
        this.live_meet_lesson.jitsi.room,
        this.live_meet_lesson.jitsi.jwt,
        '#jaas-container',
        { subject: this.live_meet_lesson.title }
      );
    },
    async setJitsiRoom() {
      if (!this.is_available) return;

      do {
        await this.$nextTick();
      } while (document.querySelector('#jaas-container') === null);

      this.setJitsiIframe();
      this.setModerationListener();
    },
    setModerationListener() {
      let setModeration = this.setModeration;
      this.jitsi_api.on('participantRoleChanged', function(event) {
        if (event.role === 'moderator') {
          setModeration();
        }
      });
    },
  },
  async created() {
    await this.setLiveMeetRoom();
  },
};
</script>

<style lang="scss" scoped>
.back-route,
.back-route:hover {
  color: $purple-dark;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.jitsi-iframe {
  height: 100vh;

  .jitsi-iframe-container {
    height: 100%;
  }
}

@media screen and (min-width: $screen-lg) {
  .jitsi-iframe {
    height: 53vw;
  }
}

@media screen and (max-width: $screen-lg) {
  .jitsi-iframe {
    height: 100vh;
  }
}
</style>
